var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { Rectangle, ScheduleIcon, TableComponent, TextComponent, KRWFomatToString, XScrollableTable, AntTag } from 'renderer/components';
import { DATE_FORMAT_INCLUDE_TIME_AAA, HEADER_HEIGHT } from 'renderer/constants';
import Row from 'antd/lib/grid/row';
import { useScheduleModal } from 'renderer/stores';
import { orderBy, useWindowSize } from 'renderer/utils';
import { ScheduleModel } from 'renderer/models';
export var getReservationColumns = function (sorter) { return [
    {
        title: '스케쥴',
        dataIndex: 'type',
        key: 'type',
        width: 50,
        render: function (_, row) {
            return row.typeString;
        }
    },
    {
        title: '예약일시',
        key: 'startedAt',
        dataIndex: ['dateOption', 'startedAt'],
        width: 150,
        render: function (value) {
            return dayjs(value).format(DATE_FORMAT_INCLUDE_TIME_AAA);
        }
    },
    {
        title: '고객명(제목)',
        key: 'title',
        dataIndex: ['title'],
        width: 120,
        render: function (_, row) {
            return row.displayName;
        }
    },
    {
        title: '연락처',
        key: 'phone',
        dataIndex: ['customer', 'phone'],
        width: 120,
        render: function (_, row) {
            var _a;
            return (_a = row.customer) === null || _a === void 0 ? void 0 : _a.phoneToString;
        }
    },
    {
        title: '예약상품',
        width: 250,
        key: 'usingString',
        dataIndex: ['usingString'],
        render: function (_, row) {
            return row.usingString;
        }
    },
    {
        title: '메모',
        key: 'description',
        dataIndex: ['description'],
        width: 380,
        render: function (value) {
            return value || '-';
        }
    },
    {
        title: '담당자',
        key: 'managerName',
        dataIndex: ['managerName'],
        width: 120,
        render: function (_, row) {
            var _a;
            var managerName = row.managerName;
            var color = (_a = row.color) === null || _a === void 0 ? void 0 : _a.manager;
            return row.type === ScheduleModel.RESERVATION_TYPE.OFF && !row.managerId ? (React.createElement(AntTag, { children: "\uC0C1\uC810\uD734\uBB34" })) : (React.createElement(Row, { align: "middle" },
                React.createElement(Rectangle, { width: 12, color: color }),
                React.createElement(TextComponent, { children: managerName, marginLeft: 5, type: "caption1" })));
        }
    },
    {
        title: '예약경로',
        key: 'provider',
        dataIndex: ['data', 'provider'],
        width: 100,
        render: function (_, row) {
            var isNaver = _ === 'naver';
            return (React.createElement(Row, null,
                React.createElement(ScheduleIcon, { type: "provider", schedule: row }),
                React.createElement(TextComponent, { children: isNaver ? '네이버' : '직접입력', type: "caption1", marginLeft: 5 })));
        }
    },
    {
        title: '예약금',
        width: 120,
        key: 'deposit',
        dataIndex: ['data', 'deposit'],
        render: function (_, row) {
            var _a;
            var deposit = (_a = row.data) === null || _a === void 0 ? void 0 : _a.deposit;
            return deposit ? (React.createElement(Row, null,
                React.createElement(ScheduleIcon, { type: "deposit", schedule: row }),
                React.createElement(TextComponent, { children: KRWFomatToString({ value: deposit }), type: "caption1", marginLeft: 5 }))) : ('-');
        }
    },
    {
        title: '예약상태',
        width: 100,
        dataIndex: ['status'],
        key: 'status',
        render: function (_, row) {
            var _a;
            var color = (_a = row.color) === null || _a === void 0 ? void 0 : _a.status;
            var statusString = row.statusToString;
            return row.isReservation ? (React.createElement(Row, { align: "middle" },
                React.createElement(TextComponent, { children: statusString, type: "caption1", marginLeft: 5 }))) : ('-');
        }
    },
    {
        title: '등록일시',
        key: 'createdAt',
        dataIndex: ['createdAt'],
        width: 150,
        render: function (value) {
            return dayjs(value).format(DATE_FORMAT_INCLUDE_TIME_AAA);
        }
    }
]; };
var TABLE_ID = 'TABLE_ID_RESERVATION_IN_PC';
export var ReservationTable = function (props) {
    var add = useScheduleModal().add;
    var dataSource = orderBy((props.schedules || []).concat(props.offList || []), 'dateOption.startedAt', 'desc');
    var height = useWindowSize().height;
    var scheduleWrapperHeight = (height || 600) - HEADER_HEIGHT;
    return (React.createElement(XScrollableTable, { id: TABLE_ID, scroll: { y: scheduleWrapperHeight - 180 }, resizable: {
            additionalColumnWidth: 20
        }, dataSource: dataSource, onRow: function (record) {
            return {
                onClick: function () {
                    return add({
                        id: record.id,
                        type: 'details',
                        schedule: record
                    });
                }
            };
        }, columns: getReservationColumns(true) }));
};
var StyledTable = styled(TableComponent)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;
