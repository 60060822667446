var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { flatten, useDebounce, useWindowSize, isArray, sum, isEmpty } from 'renderer/utils';
import { ProductQuery, StoreQuery, TicketTemplateQuery } from 'renderer/queries';
import { StoreProductModel } from 'renderer/models';
import { ButtonTabs, ButtonWithPlusCircle, BigTitleModal, EntireBigTitleModal, Icon, KRWFomatToString, ProductFormDrawer, ActionPopup, ColorTag, AlertSentenceWithIcon, TicketTemplateForm, AntTag, ScrollBar } from 'renderer/components';
import Row from 'antd/lib/row';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Col } from 'antd/lib/grid';
import { TextComponent } from 'renderer/components/Typography';
import { COLORS } from 'renderer/styles';
import notification from 'antd/lib/notification';
import { Layout } from 'common-ui';
import { Input } from 'antd/lib';
import { FluidInline } from 'common-ui/Layout';
export var ProductOrTicketSelector = function (props) {
    var _a, _b, _c;
    var _d = useWindowSize(), isPC = _d.isPC, height = _d.height;
    var createGroupMutate = ProductQuery.useCreateProductGroup();
    var createProductMutate = ProductQuery.useCreateProduct();
    var templateQuery = TicketTemplateQuery.useTicketTemplates(props.storeId);
    var inputRef = React.useRef();
    var _e = React.useState(), keyword = _e[0], setKeyword = _e[1];
    var debouncedKeyword = useDebounce(keyword, 500);
    var notFoundPlaceholder = "'".concat(debouncedKeyword, "'\uC640(\uACFC) \uC77C\uCE58\uD558\uB294 \uAC80\uC0C9\uACB0\uACFC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.");
    var isSearch = !!debouncedKeyword;
    React.useEffect(function () {
        setUsingHistory(undefined);
    }, [props.type]);
    React.useEffect(function () {
        var _a, _b;
        setVisible(!!props.visible);
        if (props.visible) {
            if (props.type === 'product') {
                var groups = (_a = props.productGroups) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !item.deletedAt; });
                if (groups.length > 0) {
                    setActiveButtonKey((_b = groups[0]) === null || _b === void 0 ? void 0 : _b.id);
                }
            }
            else {
                setActiveButtonKey('charging_money');
            }
        }
    }, [props.visible, props.type, props.productGroups]);
    var _f = React.useState(), activeButtonKey = _f[0], setActiveButtonKey = _f[1];
    var _g = React.useState(props.visible || false), visible = _g[0], setVisible = _g[1];
    var handleClose = function () {
        setVisible(false);
        setUsingHistory(undefined);
        props.onClose();
    };
    var handleCompleteSelector = function (result) {
        props.onComplete(result);
        handleClose();
    };
    var tabListItems = props.type === 'product'
        ? [
            (_a = props.productGroups) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !item.isDeleted && item.type === 'service'; }).map(function (group) { return ({
                id: group.id,
                children: group.name
            }); }),
            (_b = props.productGroups) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return !item.isDeleted && item.type === 'product'; }).map(function (group) { return ({
                id: group.id,
                children: group.name
            }); })
        ]
        : [
            [
                { children: '선불액', id: 'charging_money' },
                { children: '횟수권', id: 'count' },
                { children: '기간권', id: 'term' }
            ]
        ];
    var _h = React.useState(), productDrawer = _h[0], setProductDrawer = _h[1];
    var _j = React.useState(), ticketTemplateDrawer = _j[0], setTicketTemplateDrawer = _j[1];
    var handleProductDrawerClose = function () {
        setProductDrawer(undefined);
    };
    var handleCreateProductOrGroup = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var time, date;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.productGroupId) return [3 /*break*/, 2];
                    time = 0;
                    if (data.time !== 0) {
                        date = dayjs(data.time);
                        time = date.hour() * 60 + date.minute();
                    }
                    return [4 /*yield*/, createProductMutate.mutateAsync(__assign(__assign({}, data), { time: time, storeId: props.storeId }))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, createGroupMutate.mutateAsync(__assign(__assign({}, data), { storeId: props.storeId }))];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    handleProductDrawerClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var products = (_c = props.productGroups) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, group) {
        if (group.products) {
            group.products.forEach(function (item) {
                if ((!isArray(props.naverProductIds) || props.naverProductIds.includes(item.id)) && !item.isInside) {
                    var products_1 = group.products, productGroup = __rest(group, ["products"]);
                    acc.push(new StoreProductModel(__assign(__assign({}, item), { productGroup: productGroup })));
                }
            });
        }
        return acc;
    }, []);
    var _k = React.useState(), usingHistory = _k[0], setUsingHistory = _k[1];
    var addUsingHistory = function (using) {
        setUsingHistory(function (prev) { return (prev || []).concat(using); });
    };
    var createProductItem = function (product, group) {
        var _a, _b;
        return {
            title: "(".concat(group.name, ") ").concat(product.name),
            onClick: function () {
                var temp = getSelectedProductById(props.productGroups, product.id);
                addUsingHistory({ productId: product.id, product: temp });
            },
            onClose: undefined,
            description: product.priceWithTime,
            hasTicket: !!((_b = (_a = props.customer) === null || _a === void 0 ? void 0 : _a.activeTicketsWithoutCharging) === null || _b === void 0 ? void 0 : _b.find(function (ticket) {
                var _a;
                return ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.storeProductId) === product.id;
            }))
        };
    };
    var renderKeys = function (tabKey, keyword) {
        var _a, _b, _c;
        var listItems = [];
        var isSearchMode = !!keyword;
        if (props.type === 'product') {
            var productGroups = props.productGroups.filter(function (group) { return !group.isDeleted; });
            if (isSearchMode) {
                var products_2 = flatten(productGroups === null || productGroups === void 0 ? void 0 : productGroups.map(function (group) { return group.products || []; })).filter(function (product) {
                    var _a;
                    var targetGroup = props.productGroups.find(function (group) { return group.id === product.productGroupId; });
                    return ((!isArray(props.naverProductIds) || ((_a = props.naverProductIds) === null || _a === void 0 ? void 0 : _a.includes(product.id))) &&
                        !product.isDeleted &&
                        !product.isInside &&
                        (findSearchTarget("(".concat(targetGroup.name, ") ").concat(product.name), keyword) ||
                            findSearchTarget(targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.name, keyword) ||
                            findSearchTarget(product === null || product === void 0 ? void 0 : product.name, keyword)));
                });
                listItems = listItems.concat(products_2 === null || products_2 === void 0 ? void 0 : products_2.map(function (product) {
                    var targetGroup = props.productGroups.find(function (group) { return group.id === product.productGroupId; });
                    return createProductItem(product, targetGroup);
                }));
            }
            else {
                var targetGroup_1 = productGroups.find(function (group) { return group.id === Number(tabKey); });
                listItems = listItems.concat((_b = (_a = targetGroup_1 === null || targetGroup_1 === void 0 ? void 0 : targetGroup_1.products) === null || _a === void 0 ? void 0 : _a.filter(function (product) {
                    var _a;
                    return !product.isDeleted &&
                        !product.isInside &&
                        (!isArray(props.naverProductIds) || ((_a = props.naverProductIds) === null || _a === void 0 ? void 0 : _a.includes(product.id)));
                })) === null || _b === void 0 ? void 0 : _b.map(function (product) { return createProductItem(product, targetGroup_1); }));
            }
        }
        else if (props.type === 'ticket') {
            var templates = (_c = templateQuery.data) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return !item.isProductDeleted; });
            listItems = listItems.concat(templates === null || templates === void 0 ? void 0 : templates.filter(function (item) {
                return !item.isProductDeleted && (!isSearchMode ? item.type === tabKey : findSearchTarget(item.name, keyword));
            }).map(function (item) { return ({
                title: "".concat(item.name),
                onClick: function () { return handleCompleteSelector(item); },
                onClose: undefined,
                description: "\uAC00\uACA9:".concat(KRWFomatToString({ value: item.totalPrice }), " / \uC81C\uACF5:").concat(item.amountString),
                additional: item.additional
            }); }));
        }
        return (React.createElement(ItemRow, { items: listItems, type: props.type, isPC: isPC, debouncedKeyword: keyword, notFoundPlaceholder: notFoundPlaceholder }));
    };
    var hasUsing = !isEmpty(usingHistory);
    var Modal = isPC ? BigTitleModal : EntireBigTitleModal;
    var isProduct = props.type === 'product';
    var createTicketTemplateMutate = TicketTemplateQuery.useCreateTemplate();
    var bodyStyle = isPC ? { height: "".concat(height * 0.8 < 600 ? height * 0.8 : 600, "px") } : undefined;
    var store = StoreQuery.getMyStoreInCache();
    var renderItemList = function () {
        return isSearch ? (React.createElement("div", null, renderKeys(null, debouncedKeyword))) : (React.createElement(ButtonTabs, { tabs: tabListItems, renderByKey: function (key) { return renderKeys(key, debouncedKeyword); }, tab: activeButtonKey, onChange: function (tab) { return setActiveButtonKey(tab); } }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { afterOpenChange: function (open) {
                var _a;
                if (open) {
                    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    setKeyword('');
                }
            }, centered: isPC, width: isPC ? '800px' : undefined, styles: {
                body: bodyStyle
            }, open: visible, footer: hasUsing ? undefined : React.createElement("div", null), onCancel: handleClose, okText: hasUsing ? '상품선택 완료' : '', onOk: function () { return handleCompleteSelector(usingHistory); }, title: props.type === 'ticket' ? '선불액∙정기권 선택' : '상품 선택 또는 정기권 차감', children: React.createElement(ModalBodyWrapper, { keyword: keyword, onChange: function (ev) { return setKeyword(ev.target.value); }, hasUsing: hasUsing, onClickRemove: function (items) { return setUsingHistory(items); }, usingHistory: usingHistory, isPC: isPC, customer: props.customer, productGroups: props.productGroups, body: isProduct ? (React.createElement("div", null,
                    React.createElement(CustomerTickets, { debouncedKeyword: debouncedKeyword, isPC: isPC, onClickPurchaseTicket: props.onClickPurchaseTicket, notFoundPlaceholder: notFoundPlaceholder, customer: props.customer, addUsingHistory: addUsingHistory, naverProductIds: props.naverProductIds, productGroups: props.productGroups }),
                    React.createElement(ProductModalHeader, { title: "\uC0C1\uD488", subtitle: "(\uC2DC\uC220\u2219\uC81C\uD488)", button: React.createElement(ActionPopup, { trigger: React.createElement(ButtonWithPlusCircle, { children: isPC ? '상품 등록' : '' }), actions: [
                                {
                                    label: '1차 상품등록',
                                    onClick: function () {
                                        return setProductDrawer({
                                            grade: 'group'
                                        });
                                    }
                                },
                                {
                                    label: '2차 상품등록',
                                    onClick: function () {
                                        setProductDrawer({
                                            grade: 'product',
                                            data: {
                                                productGroupId: activeButtonKey
                                            }
                                        });
                                    }
                                }
                            ] }) }),
                    renderItemList(),
                    React.createElement(ProductFormDrawer, { drawer: productDrawer, onClose: handleProductDrawerClose, onSubmit: handleCreateProductOrGroup, groups: props.productGroups }))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(ProductModalHeader, { title: "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C", subtitle: "", button: React.createElement(ButtonWithPlusCircle, { children: isPC ? '선불액∙정기권 등록' : '', onClick: function () {
                                    setTicketTemplateDrawer({});
                                } }) }),
                        !isEmpty(tabListItems) && renderItemList(),
                        ticketTemplateDrawer && (React.createElement(TicketTemplateForm, { store: store, groups: props.productGroups, products: products, data: ticketTemplateDrawer, onClose: function () { return setTicketTemplateDrawer(undefined); }, onSubmit: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                                var payload, _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            payload = data;
                                            _b.label = 1;
                                        case 1:
                                            _b.trys.push([1, 3, , 4]);
                                            return [4 /*yield*/, createTicketTemplateMutate.mutateAsync(payload)];
                                        case 2:
                                            _b.sent();
                                            notification.success({ message: '선불액∙정기권 메뉴가 등록되었습니다' });
                                            setTicketTemplateDrawer(undefined);
                                            return [3 /*break*/, 4];
                                        case 3:
                                            _a = _b.sent();
                                            notification.success({ message: '선불액∙정기권 템플릿이 생성 중 에러 발생' });
                                            return [3 /*break*/, 4];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); } }))))) }) }),
        props.isUpdated && props.type === 'ticket' ? (React.createElement("div", null)) : props.trigger ? (props.trigger) : (React.createElement(ButtonWithPlusCircle, { children: isPC ? (props.type === 'product' ? '상품선택 또는 정기권차감' : '선불액∙정기권 선택') : '', onClick: function () { return setVisible(true); } }))));
};
var ItemRow = function (props) {
    var items = props.items, debouncedKeyword = props.debouncedKeyword, notFoundPlaceholder = props.notFoundPlaceholder, isPC = props.isPC;
    return (React.createElement(Row, { gutter: 16 }, isEmpty(items) ? (React.createElement(AlertSentenceWithIcon, { style: { marginLeft: '5px' }, descriptions: [
            debouncedKeyword
                ? notFoundPlaceholder
                : props.type === 'ticket'
                    ? '판매할 수 있는 선불액∙정기권이 없습니다. 팝업 우측의 [선불액∙정기권 등록]버튼을 클릭해 판매할 선불액∙정기권 메뉴를 등록해주세요.'
                    : '등록된 2차 상품이 없습니다. 팝업 우측의 [상품 등록] 버튼을 클릭해 2차 상품을 등록해주세요.'
        ] })) : (items.filter(Boolean).map(function (item, key) {
        return (React.createElement(ProductCardButton, { key: key, item: item, colSpan: isPC ? 8 : 24, onClick: item === null || item === void 0 ? void 0 : item.onClick, hasTicket: item.hasTicket }));
    }))));
};
var ModalBodyWrapper = function (props) {
    var _a;
    var removeUsingHistoryByIndex = function (index) {
        props.onClickRemove(props.usingHistory.filter(function (_, targetIndex) { return targetIndex !== index; }));
    };
    return (React.createElement("div", { style: { height: '100%' } },
        React.createElement(FluidInline, { style: { padding: '0 16px' } },
            React.createElement(Input, { autoFocus: true, suffix: React.createElement(Icon, { name: "search" }), placeholder: "\uAC80\uC0C9\uC5B4\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694", value: props.keyword, onChange: props.onChange })),
        React.createElement(ScrollBar, { options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } }, style: {
                padding: '16px',
                paddingTop: 0,
                height: props.hasUsing ? '70%' : "calc(100% - ".concat(props.isPC ? '0px' : '35px', ")")
            } }, props.body),
        props.hasUsing && (React.createElement(SelectedCardZone, null,
            React.createElement(Row, { gutter: 16, style: { marginTop: '10px' } }, (_a = props.usingHistory) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
                var _a, _b, _c, _d;
                var targetGroup = props.productGroups.find(function (group) { var _a; return group.id === Number((_a = item.product) === null || _a === void 0 ? void 0 : _a.productGroupId); });
                return (React.createElement(ProductCardButton, { key: index, hasTicket: !!((_b = (_a = props.customer) === null || _a === void 0 ? void 0 : _a.activeTicketsWithoutCharging) === null || _b === void 0 ? void 0 : _b.find(function (ticket) {
                        var _a;
                        return ((_a = ticket === null || ticket === void 0 ? void 0 : ticket.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.storeProductId) === item.productId;
                    })), item: {
                        title: "(".concat((targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.name) || '-', ") ").concat((_c = item.product) === null || _c === void 0 ? void 0 : _c.name),
                        description: (_d = item.product) === null || _d === void 0 ? void 0 : _d.priceWithTime
                    }, colSpan: props.isPC ? 8 : 24, onClose: function () { return removeUsingHistoryByIndex(index); } }));
            }))))));
};
var CustomerTickets = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var debouncedKeyword = props.debouncedKeyword, notFoundPlaceholder = props.notFoundPlaceholder, isPC = props.isPC;
    var getTicketItemProps = function (item) {
        var _a, _b, _c, _d;
        var title = "[".concat((_a = item.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.getTypeToString(), "]").concat(item.name);
        if (isArray(props.naverProductIds) && !props.naverProductIds.includes(item.copiedTicketTemplate.storeProductId)) {
            return null;
        }
        if (!findSearchTarget(title, debouncedKeyword)) {
            return null;
        }
        var shareInfo = item === null || item === void 0 ? void 0 : item.getRelationDescription(Number((_b = props.customer) === null || _b === void 0 ? void 0 : _b.id), __spreadArray(__spreadArray([], (_c = props.customer) === null || _c === void 0 ? void 0 : _c.mainCustomerRelations, true), (_d = props.customer) === null || _d === void 0 ? void 0 : _d.otherCustomerRelations, true));
        return {
            id: item.id,
            title: title,
            onClick: function () {
                var _a;
                var product = getSelectedProductById(props.productGroups, (_a = item.copiedTicketTemplate) === null || _a === void 0 ? void 0 : _a.storeProductId);
                if (product) {
                    props.addUsingHistory({
                        productId: product.id,
                        product: product,
                        ticketHistory: { ticketId: Number(item.id) }
                    });
                }
                else {
                    notification.error({ message: '상품이 삭제된 정기권은 사용할 수 없습니다.' });
                }
            },
            onClose: undefined,
            shareInfo: shareInfo,
            description: item.getTicketDataToString()
        };
    };
    var ticketItems = ((_c = (_b = (_a = props.customer) === null || _a === void 0 ? void 0 : _a.activeTicketsWithoutCharging) === null || _b === void 0 ? void 0 : _b.map(getTicketItemProps)) === null || _c === void 0 ? void 0 : _c.filter(Boolean)) || [];
    var sharedTicketItems = ((_f = (_e = (_d = props.customer) === null || _d === void 0 ? void 0 : _d.activeSharedTicketsWithoutCharging) === null || _e === void 0 ? void 0 : _e.map(getTicketItemProps)) === null || _f === void 0 ? void 0 : _f.filter(Boolean)) || [];
    var items = ticketItems.concat(sharedTicketItems);
    var sharedPrepaid = sum((_h = (_g = props.customer) === null || _g === void 0 ? void 0 : _g.activeShareTicketsCharging) === null || _h === void 0 ? void 0 : _h.map(function (ticket) { var _a; return (_a = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _a === void 0 ? void 0 : _a.remain; })) || 0;
    var prepaid = sum((_k = (_j = props.customer) === null || _j === void 0 ? void 0 : _j.activeTicketsCharging) === null || _k === void 0 ? void 0 : _k.map(function (ticket) { var _a; return (_a = ticket === null || ticket === void 0 ? void 0 : ticket.data) === null || _a === void 0 ? void 0 : _a.remain; }));
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductModalHeader, { title: "\uC120\uBD88\uC561 \u2022 \uD69F\uC218\uAD8C \u2022 \uAE30\uAC04\uAD8C", button: props.customer ? { title: '선불액∙정기권 판매', onClick: props.onClickPurchaseTicket } : null }),
        React.createElement(TextComponent, { type: "caption1", color: COLORS.gray2, children: "\uC120\uBD88\uC561: ".concat(KRWFomatToString({ value: prepaid }), " / \uACF5\uC720\uC120\uBD88\uC561: ").concat(KRWFomatToString({
                value: sharedPrepaid
            })) }),
        React.createElement(Row, { gutter: 16 }, props.customer ? (isEmpty(items || []) ? (React.createElement(AlertSentenceWithIcon, { descriptions: [
                debouncedKeyword
                    ? notFoundPlaceholder
                    : "\uACE0\uAC1D\uC774 \uBCF4\uC720\uD55C \uC120\uBD88\uC561 \u2022 \uD69F\uC218\uAD8C \u2022 \uAE30\uAC04\uAD8C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. \uD31D\uC5C5 \uC6B0\uCE21 \uC0C1\uB2E8\uC758 [\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uD310\uB9E4(+)] \uBC84\uD2BC\uC744 \uD074\uB9AD\uD574 \uC815\uAE30\uAD8C\uC744 \uD310\uB9E4\uD574\uBCF4\uC138\uC694."
            ] })) : (items.map(function (item) {
            return (React.createElement(ProductCardButton, { key: "".concat(item.id, "_").concat(item.title, "_").concat(debouncedKeyword), item: item, colSpan: isPC ? 8 : 24, onClick: item === null || item === void 0 ? void 0 : item.onClick }));
        }))) : (React.createElement(AlertSentenceWithIcon, { descriptions: [
                "\uACE0\uAC1D\uC815\uBCF4\uB97C \uC785\uB825\uD574\uC57C \uC120\uBD88\uC561 \u2022 \uD69F\uC218\uAD8C \u2022 \uAE30\uAC04\uAD8C\uC744 \uD655\uC778\uD558\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4. \uD31D\uC5C5\uC744 \uB2EB\uACE0 \uACE0\uAC1D\uC815\uBCF4\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694."
            ] })))));
};
var findSearchTarget = function (target, keyword) {
    return !keyword || (target === null || target === void 0 ? void 0 : target.includes(keyword));
};
var ProductModalHeader = function (props) {
    var title = props.title, subtitle = props.subtitle, button = props.button;
    return (React.createElement(Layout.FluidInline, { align: "center", justify: "start", stretch: 0, style: { marginTop: '10px' } },
        React.createElement(React.Fragment, null,
            React.createElement(Layout.Inline, null,
                React.createElement(TextComponent, { children: title, type: "subtitle2" }),
                React.createElement(TextComponent, { children: subtitle, marginLeft: 4, color: COLORS.gray3, type: "caption" })),
            (button === null || button === void 0 ? void 0 : button.title) ? (React.createElement(ButtonWithPlusCircle, { children: button.title, onClick: button.onClick, style: { paddingLeft: '0px' } })) : props.button ? (props.button) : (React.createElement("div", null)))));
};
var SelectedCardZone = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 30%;\n  background-color: var(--gray-7);\n  padding: 12px 20px;\n  overflow-y: scroll;\n"], ["\n  height: 30%;\n  background-color: var(--gray-7);\n  padding: 12px 20px;\n  overflow-y: scroll;\n"])));
var ProductCardButton = function (props) {
    var item = props.item;
    var additional = item === null || item === void 0 ? void 0 : item.additional;
    return (React.createElement(Col, { span: props.colSpan },
        React.createElement(CardWrapper, { onClick: props.onClick ? function () { return props.onClick(); } : undefined, isPrimary: item.isPrimary },
            React.createElement(Row, { align: "middle", justify: "space-between", wrap: false },
                React.createElement(Col, { flex: 1 },
                    React.createElement(Row, { align: "middle" },
                        item.isPrimary ? React.createElement(Icon, { name: "plus", color: COLORS.primary1, size: 24 }) : null,
                        React.createElement(Row, { wrap: false },
                            React.createElement(Col, { flex: 1 },
                                React.createElement(TextComponent, { ellipsis: true, children: item.title, color: item.isPrimary ? COLORS.primary1 : COLORS.black, marginRight: 10 })),
                            additional && (React.createElement(TextComponent, { ellipsis: true, style: { width: '80px' }, children: additional, color: COLORS.gray2, type: "caption1" }))),
                        props.hasTicket && React.createElement(ColorTag, { children: "\uC815\uAE30\uAD8C", color: COLORS.tag5 })),
                    React.createElement(Row, null,
                        React.createElement(TextComponent, { children: item.description, color: COLORS.gray2, ellipsis: true, marginRight: 4 }),
                        item.shareInfo ? React.createElement(AntTag, { children: item.shareInfo }) : React.createElement("div", null))),
                props.onClose && (React.createElement(Col, { style: { padding: '10px' }, onClick: props.onClose },
                    React.createElement(Icon, { name: "close" })))))));
};
var CardWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 50px;\n  border-radius: 4px;\n  border: solid 1px ", ";\n  background-color: var(--white);\n  margin-bottom: 6px;\n  cursor: pointer;\n\n  & > div {\n    height: 100%;\n    padding-left: 10px;\n  }\n"], ["\n  height: 50px;\n  border-radius: 4px;\n  border: solid 1px ", ";\n  background-color: var(--white);\n  margin-bottom: 6px;\n  cursor: pointer;\n\n  & > div {\n    height: 100%;\n    padding-left: 10px;\n  }\n"])), function (props) { return (props.isPrimary ? COLORS.primary1 : COLORS.gray5); });
var getSelectedProductById = function (productGroupQuery, id) {
    var product;
    productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.map(function (group) {
        var _a;
        var temp = (_a = group === null || group === void 0 ? void 0 : group.products) === null || _a === void 0 ? void 0 : _a.find(function (item) { return (item === null || item === void 0 ? void 0 : item.id) === Number(id); });
        if (temp) {
            product = new StoreProductModel(__assign(__assign({}, temp), { productGroup: group }));
        }
    });
    return product;
};
var templateObject_1, templateObject_2;
