var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ReservationCalendarHeader, ScheduleBlock, SchedulePreviewer, StyledDailyCalendar, TextComponent } from 'renderer/components/';
import React from 'react';
import styled from 'styled-components';
import { getScheduleFromLocal, sum, times, uniqBy } from 'renderer/utils';
import { ScheduleModel } from 'renderer/models';
import dayjs from 'dayjs';
import { COLORS } from 'renderer/styles';
import Modal from 'antd/lib/modal';
export var FallbackCalendar = function (props) {
    var _a, _b;
    var DATE_COUNT = 5;
    var today = dayjs().startOf('day').toDate();
    var _c = React.useState(today), currentDate = _c[0], setCurrentDate = _c[1];
    var status = props.status;
    var schedules = getScheduleFromLocal() || [];
    var convertToEvent = function (item) { return ({
        title: item.displayName,
        start: dayjs(item.dateOption.startedAt).toDate(),
        end: dayjs(item.dateOption.endedAt).toDate(),
        resourceId: (item.manager && item.managerId) || -1,
        schedule: item,
        view: 'day'
    }); };
    var managers = uniqBy(schedules.map(function (schedule) { return schedule.manager; }), 'id');
    var events = schedules
        .filter(function (item) {
        return item.type === ScheduleModel.RESERVATION_TYPE.RESERVATION && dayjs(currentDate).isSame(item.startedAt, 'day');
    })
        .map(convertToEvent);
    var _d = events.length > 0
        ? events.reduce(function (_a, event) {
            var min = _a[0], max = _a[1];
            return [dayjs(Math.min(min, event.start)).toDate(), dayjs(Math.max(max, event.end)).toDate()];
        }, [dayjs(events[0].start).toDate(), dayjs(events[0].end).toDate()])
        : [dayjs().startOf('day').toDate(), dayjs().endOf('day').date()], min = _d[0], max = _d[1];
    var resources = (_b = (_a = managers
        .filter(Boolean)) === null || _a === void 0 ? void 0 : _a.concat({
        id: -1,
        displayName: '담당자 없음',
        count: sum(events.map(function (event) { return (event.resourceId === -1 ? 1 : 0); }))
    })) === null || _b === void 0 ? void 0 : _b.map(function (item) {
        return ({
            id: item === null || item === void 0 ? void 0 : item.id,
            label: item === null || item === void 0 ? void 0 : item.displayName,
            count: sum(events.map(function (event) { return (event.resourceId === item.id ? 1 : 0); })),
            date: currentDate
        });
    });
    var _e = React.useState(null), selectedSchedule = _e[0], setSelectedSchedule = _e[1];
    var availableDates = times(10, function (i) {
        return i > 5
            ? dayjs(today)
                .add(i - 5, 'day')
                .toDate()
            : dayjs(today).subtract(i, 'day').toDate();
    });
    var minDate = dayjs(today).subtract(DATE_COUNT, 'day').format('YYYY-MM-DD');
    var maxDate = dayjs(today).add(DATE_COUNT, 'day').format('YYYY-MM-DD');
    return (React.createElement(Wrapper, null,
        React.createElement(ReservationInfoModal, { schedule: selectedSchedule, onClose: function () { return setSelectedSchedule(null); } }),
        React.createElement(HeaderWrapper, null,
            React.createElement(TextComponent, { type: "headline2", marginBottom: 12 }, status.title || '팅커뷰 서비스 점검 안내'),
            React.createElement(TextComponent, { type: "body1", marginBottom: 8 }, status.message ||
                "\uB354\uC6B1 \uC548\uC815\uC801\uC778 \uC11C\uBE44\uC2A4 \uC81C\uACF5\uC744 \uC704\uD574 \uC11C\uBC84 \uC810\uAC80\uC774 \uC788\uC744 \uC608\uC815\uC785\uB2C8\uB2E4. 5\uC6D4 9\uC77C 03\uC2DC~ 06\uC2DC \uC0AC\uC774\uC5D0 \uC9C4\uD589\uB420 \uC608\uC815\uC774\uBA70 \uD574\uB2F9\n          \uC2DC\uAC04 \uB3D9\uC548 \uC11C\uBE44\uC2A4 \uC774\uC6A9\uC774 \uBD88\uAC00\uB2A5\uD569\uB2C8\uB2E4."),
            React.createElement(TextComponent, { type: "body2", color: COLORS.gray2, marginBottom: 28 },
                "\uCD5C\uADFC \uAE30\uB85D :",
                availableDates ? "".concat(minDate, "~").concat(maxDate) : '')),
        React.createElement(CalendarWrapper, null,
            React.createElement(ReservationCalendarHeader, { hideControl: true, availableDates: availableDates, searchParams: {}, onSearchParamChange: function () { }, onDateChange: function (date) {
                    setCurrentDate(date);
                }, date: currentDate, view: 'day' }),
            React.createElement(StyledDailyCalendar, { showAllEvents: true, selectable: true, date: currentDate, events: events, view: "day", eventPropGetter: function (event) {
                    return {
                        style: {
                            padding: 0,
                            backgroundColor: '#fff',
                            border: '0px solid black',
                            // [NO.719]주캘린더에서 예약이 가려져 안보여요. 해결하기 위해 넣음 함수 자체 제작
                            // FIXME: 지워야 함 overlap 함수 없앨때
                            zIndex: event.zIndex
                        },
                        label: null
                    };
                }, onSelectEvent: function (data, event) {
                    event.stopPropagation();
                    event.preventDefault();
                    setSelectedSchedule(data.schedule);
                }, components: {
                    event: function (data) {
                        var _a, _b, _c;
                        return ((_a = data === null || data === void 0 ? void 0 : data.event) === null || _a === void 0 ? void 0 : _a.schedule) ? (React.createElement(ScheduleBlock, { key: (_b = data.schedule) === null || _b === void 0 ? void 0 : _b.id, onClick: function () {
                                var _a;
                                setSelectedSchedule((_a = data.event) === null || _a === void 0 ? void 0 : _a.schedule);
                            }, schedule: (_c = data.event) === null || _c === void 0 ? void 0 : _c.schedule, blockSettings: [], disablePopover: true })) : (React.createElement("div", null));
                    }
                }, resizable: false, drilldownView: null, min: min, max: dayjs(min).isSame(max, 'day') ? dayjs(max).toDate() : dayjs(min).endOf('day').toDate(), resources: resources, resourceTitleAccessor: ResourceItem, resourceIdAccessor: function (resource) { return resource.id; } })),
        React.createElement(Footer, null)));
};
var Footer = function () {
    return (React.createElement(FooterWrapper, null,
        React.createElement(TextComponent, { type: "body2" }, "\uC5F0\uB77D\uCC98 : 1660-1890"),
        React.createElement(TextComponent, { type: "body2" }, "C. Hatchling")));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100vw;\n  height: 100vh;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n"])));
var HeaderWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* \uC790\uB3D9 \uB192\uC774 \uC870\uC815 */\n  flex: 0 1 auto;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* \uC790\uB3D9 \uB192\uC774 \uC870\uC815 */\n  flex: 0 1 auto;\n"])));
var FooterWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* \uC790\uB3D9 \uB192\uC774 \uC870\uC815 */\n  flex: 0 1 auto;\n  padding-top: 32px;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  /* \uC790\uB3D9 \uB192\uC774 \uC870\uC815 */\n  flex: 0 1 auto;\n  padding-top: 32px;\n"])));
var CalendarWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 0px; /* \uB0A8\uC740 \uBAA8\uB4E0 \uACF5\uAC04\uC744 \uCC28\uC9C0 */\n  border-radius: 16px;\n  background: var(--Color-Neutral-000, #fff);\n  overflow: hidden; /* \uCE98\uB9B0\uB354\uAC00 \uB118\uCE60 \uB54C \uC2A4\uD06C\uB864 \uCC98\uB9AC */\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n\n  & > div:nth-child(2) {\n    height: calc(100% - 70px);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 0px; /* \uB0A8\uC740 \uBAA8\uB4E0 \uACF5\uAC04\uC744 \uCC28\uC9C0 */\n  border-radius: 16px;\n  background: var(--Color-Neutral-000, #fff);\n  overflow: hidden; /* \uCE98\uB9B0\uB354\uAC00 \uB118\uCE60 \uB54C \uC2A4\uD06C\uB864 \uCC98\uB9AC */\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n\n  & > div:nth-child(2) {\n    height: calc(100% - 70px);\n  }\n"])));
var ReservationInfoModal = React.memo(function (props) {
    var open = !!props.schedule;
    return (React.createElement(StyledModal, { centered: true, footer: null, closable: false, open: open, onCancel: props.onClose }, open && React.createElement(SchedulePreviewer, { schedule: props.schedule, onCloseModal: props.onClose, isFallbackCalendar: true })));
});
var StyledModal = styled(Modal)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & .ant-modal-body {\n    padding: 0 !important;\n  }\n\n  & .ant-modal-content {\n    padding: 0;\n    border-radius: 4px;\n    border: solid 1px var(--gray-6);\n    position: relative;\n    background-color: #fff;\n    background-clip: padding-box;\n    box-shadow:\n      0 0px 0px 0px rgb(0 0 0 / 12%),\n      0 0px 0px 0px rgb(0 0 0 / 8%),\n      0 0px 0px 0px rgb(0 0 0 / 5%);\n    pointer-events: auto;\n  }\n"], ["\n  & .ant-modal-body {\n    padding: 0 !important;\n  }\n\n  & .ant-modal-content {\n    padding: 0;\n    border-radius: 4px;\n    border: solid 1px var(--gray-6);\n    position: relative;\n    background-color: #fff;\n    background-clip: padding-box;\n    box-shadow:\n      0 0px 0px 0px rgb(0 0 0 / 12%),\n      0 0px 0px 0px rgb(0 0 0 / 8%),\n      0 0px 0px 0px rgb(0 0 0 / 5%);\n    pointer-events: auto;\n  }\n"])));
var ResourceItem = function (props) {
    return (React.createElement(DayHeaderInWeek, null,
        React.createElement(TextComponent, { children: props.label, type: "description2", marginRight: 5 })));
};
var DayHeaderInWeek = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border: 1px solid ", ";\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  border: 1px solid ", ";\n  background-color: ", ";\n"])), function (props) { return (props.$isToday ? COLORS.primary1 : COLORS.white); }, COLORS.gray7);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
