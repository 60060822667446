var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useWindowSize, isEmpty, sum } from 'renderer/utils';
import { useForm, useFormState } from 'react-hook-form';
import Button from 'antd/lib/button';
import { CustomerQuery, ManagerQuery, ProductQuery, StoreQuery } from 'renderer/queries';
import { getOtherDataFromUsingData, TicketTemplateModel, TICKET_TYPE, EXTENDED_SALES_METHODS, ScheduleModel } from 'renderer/models';
import { FluidRow, KRWFomatToString, Marginer, TextComponent, Icon, RegisterButtonWithOptions, Rectangle, AlertSentenceWithIcon, NaverPayCollapse, AntButton, useEntireLoader } from 'renderer/components';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { PaymentInputs } from './PaymentInputs';
import { ProductOrTicketSelector } from './ProductOrTicketSelector';
import { ProductInputList, TicketInput, ReservationFeeCard, PriceAdjustInput } from './ItemInputs';
import { HookForm } from 'renderer/components/Forms/HookForm';
import { HookFormInputs } from 'renderer/components/Forms/Inputs';
import { Col } from 'antd/lib/grid';
import { createUUID, useLocalStorage } from 'renderer/utils';
import { LOCAL_STORAGE_KEY_PAID_AT_STANDARD } from 'renderer/constants';
export var SalesFormContainer = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var isUpdated = !!((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id);
    var isPC = useWindowSize().isPC;
    var methods = useForm({
        mode: 'onBlur',
        defaultValues: {
            paidAt: new Date(),
            customerId: (_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.customerId
        }
    });
    var customerId = methods.watch('customerId');
    var customerIdIsChanged = methods.getFieldState('customerId');
    var paidAt = methods.watch('paidAt');
    var _k = React.useState(createUUID()), formId = _k[0], setFormId = _k[1];
    var mode = useLocalStorage(LOCAL_STORAGE_KEY_PAID_AT_STANDARD, 'schedule')[0];
    var loader = useEntireLoader();
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        methods.reset(__assign(__assign(__assign({ type: (_a = props.data) === null || _a === void 0 ? void 0 : _a.type, customerId: (_c = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customerId }, (_d = props.data) === null || _d === void 0 ? void 0 : _d.data), (_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.paymentHistoryForm), { paidAt: isUpdated || mode === 'schedule' ? ((_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.paidAt) || new Date() : new Date() }));
        setFormId(createUUID());
        if (isPC) {
            loader.show();
            var timer_1 = setTimeout(function () {
                loader.hide();
            }, 100);
            // Cleanup function to clear the timer if the component unmounts
            return function () { return clearTimeout(timer_1); };
        }
    }, [props.data]);
    React.useEffect(function () {
        setFocus();
    }, [formId]);
    var setFocus = function () {
        var _a, _b;
        if ((_a = customerSelectorRef === null || customerSelectorRef === void 0 ? void 0 : customerSelectorRef.current) === null || _a === void 0 ? void 0 : _a.focus) {
            (_b = customerSelectorRef === null || customerSelectorRef === void 0 ? void 0 : customerSelectorRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    };
    var store = StoreQuery.getMyStoreInCache();
    var customerSelectorRef = React.createRef();
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, payload;
        return __generator(this, function (_a) {
            if (!values.id) {
                payload = __assign(__assign({}, values), { storeId: store.id });
                props.onSubmit(payload);
            }
            else {
                payload = __assign(__assign({}, values), { storeId: store.id, changed: methods.formState.dirtyFields });
                props.onSubmit(payload);
            }
            return [2 /*return*/];
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var isDeletedCustomer = isUpdated && !!((_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.customerId) && !((_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.customer) && !customerIdIsChanged.isDirty;
    var customerDetailQuery = CustomerQuery.useCustomerDetail({ id: customerId, storeId: store.id }, {
        enabled: !!customerId && !!(store === null || store === void 0 ? void 0 : store.id) && !isDeletedCustomer
    });
    var productGroupQuery = ProductQuery.useProducts({ storeId: store.id, paranoid: false }, { enabled: !!(store === null || store === void 0 ? void 0 : store.id) });
    React.useEffect(function () {
        var _a;
        if (!customerId) {
            customerDetailQuery.remove();
        }
        if (((_a = customerDetailQuery.data) === null || _a === void 0 ? void 0 : _a.id) !== customerId) {
            // 고객 아이디 변경 시 티켓 사용 취소 (가격이나 다른 것들도 초기화 해야하나??)
            if (methods.getValues('type') === 'ticket') {
                methods.trigger('customerId');
            }
            else {
                var prevUsings = methods.getValues('usingHistory') || [];
                if ((prevUsings === null || prevUsings === void 0 ? void 0 : prevUsings.length) > 0) {
                    methods.setValue('usingHistory', (methods.getValues('usingHistory') || []).map(function (item) {
                        return __assign(__assign({}, item), { ticketHistory: { ticketId: null } });
                    }), {
                        shouldValidate: true
                    });
                    methods.trigger('usingHistory');
                }
            }
        }
    }, [customerId]);
    React.useEffect(function () {
        var _a, _b;
        if (!isUpdated) {
            var prevTicket = methods.getValues('ticket');
            if (prevTicket &&
                prevTicket.ticketTemplateId &&
                prevTicket.copiedTicketTemplate &&
                ((_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.type) === 'ticket') {
                var copied = __assign({}, prevTicket.copiedTicketTemplate);
                delete copied.totalPrice;
                delete copied.benefitString;
                var template = new TicketTemplateModel(copied);
                methods.setValue('ticket', __assign(__assign({}, prevTicket), { data: template.type === TicketTemplateModel.TICKET_TYPE.TERM
                        ? template.getDefaultTicketData(paidAt)
                        : prevTicket.data, expiredAt: (_b = template.getExpiredAt(paidAt)) === null || _b === void 0 ? void 0 : _b.toDate() }));
                methods.trigger('ticket');
            }
        }
    }, [paidAt]);
    return (React.createElement(SalesFormContainerWrapper, null, isPC ? (React.createElement(PCSalesForm, __assign({}, props, { onSubmit: onSubmit, onCancel: props.onCancel, methods: methods, products: (_j = productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.data) === null || _j === void 0 ? void 0 : _j.groups, customer: customerDetailQuery.data, onClickPurchaseTicket: props.onClickPurchaseTicket, store: store, formId: formId, customerSelectorRef: customerSelectorRef }))) : (React.createElement(MobileSalesForm, __assign({}, props, { onSubmit: onSubmit, formId: formId, methods: methods, products: productGroupQuery === null || productGroupQuery === void 0 ? void 0 : productGroupQuery.data.groups, customer: customerDetailQuery.data, onClickPurchaseTicket: props.onClickPurchaseTicket, store: store })))));
};
var SalesFormContainerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  & > form {\n    height: 100%;\n  }\n"], ["\n  height: 100%;\n  & > form {\n    height: 100%;\n  }\n"])));
export var MobileSalesForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var isProduct = ((_a = props.data) === null || _a === void 0 ? void 0 : _a.type) === 'product';
    var methods = props.methods;
    var salesData = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data;
    var formState = useFormState({ control: methods.control });
    var _u = React.useState('select'), step = _u[0], setStep = _u[1];
    var isBookingFee = [
        ScheduleModel.RESERVATION_STATUS.CANCELLED_CUSTOMR,
        ScheduleModel.RESERVATION_STATUS.CANCELLED_STORE,
        ScheduleModel.RESERVATION_STATUS.NOSHOW
    ].includes(salesData === null || salesData === void 0 ? void 0 : salesData.scheduleStatus);
    var disabledSubmit = (!props.isDirty && isEmpty(formState.dirtyFields)) || !isEmpty(formState.errors);
    var finalPrice = isProduct
        ? sum((_c = methods
            .getValues('usingHistory')) === null || _c === void 0 ? void 0 : _c.map(function (history) { return getOtherDataFromUsingData(history, props.customer).finalPrice; }))
        : methods.getValues('ticket.copiedTicketTemplate.totalPrice');
    var isPossibleNext = isProduct || isBookingFee
        ? !isEmpty(methods.getValues('usingHistory')) && isEmpty(formState.errors.usingHistory)
        : !isEmpty(methods.getValues('ticket')) &&
            isEmpty(formState.errors.ticket) &&
            isEmpty(formState.errors.customerId) &&
            !!methods.getValues('customerId');
    var _v = React.useState(false), selectorVisible = _v[0], setSelectorVisible = _v[1];
    React.useEffect(function () {
        setStep('select');
    }, [props.formId]);
    var me = ManagerQuery.getLoginManagerInCache();
    var isUpdated = salesData === null || salesData === void 0 ? void 0 : salesData.id;
    var usingHistory = props.methods.getValues('usingHistory');
    var handleClickPurchaseTicket = React.useCallback(function () {
        if (props.customer) {
            props.customer.id && props.onClickPurchaseTicket(Number(props.customer.id));
        }
    }, [(_d = props.customer) === null || _d === void 0 ? void 0 : _d.id]);
    var handleComplete = React.useCallback(function (result) {
        handleSelectProductOrTicket(me, props.methods, isProduct, result);
    }, [me.id, isProduct, props.methods]);
    var handlOpenSelector = React.useCallback(function () {
        setSelectorVisible(true);
    }, [setSelectorVisible]);
    var handlCloseSelector = React.useCallback(function () {
        setSelectorVisible(false);
    }, [setSelectorVisible]);
    var isSelect = step === 'select';
    return (React.createElement(HookForm, { methods: props.methods, onSubmit: props.onSubmit }, isSelect ? (React.createElement(MobileSalesFormResult, { style: { display: 'flex' } },
        React.createElement(Row, { justify: "space-between", style: { padding: '20px 20px 0px 20px' }, wrap: false },
            React.createElement(Col, null,
                React.createElement(Row, { wrap: false },
                    React.createElement(TimeBaseSelector, { data: props.data, methods: props.methods, isShown: !!((salesData === null || salesData === void 0 ? void 0 : salesData.scheduleId) && (salesData === null || salesData === void 0 ? void 0 : salesData.scheduleTime) && !isUpdated), modeTime: salesData === null || salesData === void 0 ? void 0 : salesData.scheduleTime, modeKey: 'schedule', modeLabel: "\uC608\uC57D\uC2DC\uAC04", modeLocalKey: LOCAL_STORAGE_KEY_PAID_AT_STANDARD }),
                    React.createElement(HookFormInputs.DateSelector, { controlProps: { name: 'paidAt', control: (_e = props.methods) === null || _e === void 0 ? void 0 : _e.control }, inputProps: {} }))),
            !isBookingFee && (React.createElement(ProductOrTicketSelector, { onComplete: handleComplete, isUpdated: (_g = (_f = props.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.isUpdated, storeId: (_h = props.store) === null || _h === void 0 ? void 0 : _h.id, type: (_j = props.data) === null || _j === void 0 ? void 0 : _j.type, customer: props.customer, productGroups: props.products, onClickPurchaseTicket: handleClickPurchaseTicket, onClose: handlCloseSelector, visible: selectorVisible }))),
        React.createElement(CustomerSelector, __assign({}, props)),
        React.createElement(MobileProductInputWrapper, null,
            ((_l = (_k = props.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.scheduleNaverPayInfo) && (React.createElement(NaverPayCollapse, { result: (_o = (_m = props.data) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.scheduleNaverPayInfo, isBookingFee: isBookingFee })),
            isBookingFee ? (React.createElement("div", null,
                React.createElement(ReservationFeeCard, __assign({}, props)))) : (React.createElement("div", null,
                ((_q = (_p = props.customer) === null || _p === void 0 ? void 0 : _p.meta) === null || _q === void 0 ? void 0 : _q.prepaid) && ((_r = props.data) === null || _r === void 0 ? void 0 : _r.type) === 'product' ? (React.createElement(AlertSentenceWithIcon, { descriptions: ['선불액차감은 결제진행 버튼을 눌러 사용할 수 있습니다.'], style: { marginBottom: '10px' } })) : (React.createElement("div", null)),
                isProduct ? (React.createElement(React.Fragment, null,
                    React.createElement(PriceAdjustInput, { methods: methods, usingHistory: usingHistory }),
                    React.createElement(ProductInputList, __assign({}, props, { onClickEmpty: handlOpenSelector })))) : (React.createElement(TicketInput, __assign({}, props, { onClickEmpty: handlOpenSelector }))))),
            React.createElement(Marginer, { height: 10 }),
            React.createElement(HookFormInputs.Textarea, { label: "\uBA54\uBAA8", inputProps: {}, controlProps: {
                    name: "memo",
                    control: props.methods.control
                } }),
            React.createElement(Marginer, { height: 30 })),
        React.createElement(MobilePaymentContiner, { finalPrice: finalPrice, onCancel: props.onCancel, onOk: function () { return setStep('payment'); }, disabled: !isPossibleNext }))) : (React.createElement(MobileSalesFormResult, { style: { display: 'flex' } },
        React.createElement("div", { className: "payment" },
            React.createElement(PaymentInputs, __assign({}, props, { parentType: "sales", hideMethods: getHideMethods(props.methods) }))),
        React.createElement(SalesModalFooter, { left: { buttonProps: { type: 'primary', children: '이전단계', onClick: function () { return setStep('select'); } } }, right: {
                element: (React.createElement(Col, { style: { width: '50%' } },
                    React.createElement(RegisterButtonWithOptions, __assign({ registerType: "sales" }, {
                        children: ((_t = (_s = props.data) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.isUpdated) ? '매출수정' : '매출등록',
                        onClick: props.onSubmit,
                        disabled: disabledSubmit
                    }))))
            } })))));
};
export var MobilePaymentContiner = function (props) {
    return (React.createElement(MobileProductInputFooter, null,
        React.createElement(TextComponent, { type: "headline3", textAlign: "center", children: "\uACB0\uC81C\uAE08\uC561: ".concat(KRWFomatToString({ value: props.finalPrice || 0 })), marginBottom: 10 }),
        React.createElement(FluidRow, { justify: "space-between", gutter: 8 },
            React.createElement(Col, { span: 12 },
                React.createElement(AntButton, { type: "primary", ghost: true, onClick: props.onCancel }, "\uB2EB\uAE30")),
            React.createElement(Col, { span: 12 },
                React.createElement(AntButton, { disabled: props.disabled, type: "primary", onClick: props.onOk }, "\uACB0\uC81C\uC9C4\uD589")))));
};
var MobileProductInputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: scroll;\n  flex: 1;\n  padding: 20px;\n"], ["\n  overflow: scroll;\n  flex: 1;\n  padding: 20px;\n"])));
export var MobileProductInputFooter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100px;\n  border-top: solid 1px var(--gray-6);\n  padding: 20px;\n\n  & button {\n    width: 100%;\n  }\n"], ["\n  height: 100px;\n  border-top: solid 1px var(--gray-6);\n  padding: 20px;\n\n  & button {\n    width: 100%;\n  }\n"])));
export var PCSalesForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var isProduct = ((_a = props.data) === null || _a === void 0 ? void 0 : _a.type) === 'product';
    var salesData = (_b = props.data) === null || _b === void 0 ? void 0 : _b.data;
    var isBookingFee = [
        ScheduleModel.RESERVATION_STATUS.CANCELLED_CUSTOMR,
        ScheduleModel.RESERVATION_STATUS.CANCELLED_STORE,
        ScheduleModel.RESERVATION_STATUS.NOSHOW
    ].includes(salesData === null || salesData === void 0 ? void 0 : salesData.scheduleStatus);
    var formState = useFormState({ control: props.methods.control });
    var disabled = (!props.isDirty && isEmpty(formState.dirtyFields) && !!((_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.id)) || !isEmpty(formState.errors);
    var usingHistory = props.methods.getValues('usingHistory');
    var _0 = React.useState(false), selectorVisible = _0[0], setSelectorVisible = _0[1];
    var handleClickEmpty = function () {
        setSelectorVisible(true);
    };
    var isUpdated = !!(salesData === null || salesData === void 0 ? void 0 : salesData.id);
    var me = ManagerQuery.getLoginManagerInCache();
    return (React.createElement("form", { onSubmit: props.onSubmit },
        React.createElement("div", { style: { display: 'none' } },
            React.createElement("input", { type: "text", name: "type" })),
        React.createElement(PCSalesFormRow, { wrap: false },
            React.createElement(PCSalesFormLeft, null, isBookingFee ? (React.createElement("div", null,
                React.createElement(Row, { justify: "start" },
                    React.createElement(TimeBaseSelector, { data: props.data, methods: props.methods, isShown: !!((salesData === null || salesData === void 0 ? void 0 : salesData.scheduleId) && (salesData === null || salesData === void 0 ? void 0 : salesData.scheduleTime) && !isUpdated), modeTime: (_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.scheduleTime, modeKey: 'schedule', modeLabel: "\uC608\uC57D\uC2DC\uAC04", modeLocalKey: LOCAL_STORAGE_KEY_PAID_AT_STANDARD }),
                    React.createElement(HookFormInputs.DateSelector, { controlProps: { name: 'paidAt', control: (_g = props.methods) === null || _g === void 0 ? void 0 : _g.control }, inputProps: {} })),
                React.createElement(Marginer, { height: 18 }),
                ((_j = (_h = props.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.scheduleNaverPayInfo) && (React.createElement(NaverPayCollapse, { result: (_l = (_k = props.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.scheduleNaverPayInfo, isBookingFee: true })),
                React.createElement(Marginer, { height: 18 }),
                React.createElement(ReservationFeeCard, __assign({}, props)))) : (React.createElement("div", null,
                React.createElement(Row, { justify: "space-between" },
                    React.createElement(TimeBaseSelector, { data: props.data, methods: props.methods, isShown: !!((salesData === null || salesData === void 0 ? void 0 : salesData.scheduleId) && (salesData === null || salesData === void 0 ? void 0 : salesData.scheduleTime) && !isUpdated), modeTime: (_o = (_m = props.data) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.scheduleTime, modeKey: 'schedule', modeLabel: "\uC608\uC57D\uC2DC\uAC04", modeLocalKey: LOCAL_STORAGE_KEY_PAID_AT_STANDARD }),
                    React.createElement(HookFormInputs.DateSelector, { controlProps: { name: 'paidAt', control: (_p = props.methods) === null || _p === void 0 ? void 0 : _p.control }, inputProps: {} }),
                    React.createElement(ProductOrTicketSelector, { onComplete: function (result) { return handleSelectProductOrTicket(me, props.methods, isProduct, result); }, isUpdated: (_r = (_q = props.data) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.isUpdated, storeId: (_s = props.store) === null || _s === void 0 ? void 0 : _s.id, type: (_t = props.data) === null || _t === void 0 ? void 0 : _t.type, customer: props.customer, productGroups: props.products, onClickPurchaseTicket: function () {
                            if (props.customer) {
                                props.customer.id && props.onClickPurchaseTicket(Number(props.customer.id));
                            }
                        }, visible: selectorVisible, onClose: function () { return setSelectorVisible(false); } })),
                React.createElement(Marginer, { height: 10 }),
                ((_v = (_u = props.data) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.scheduleNaverPayInfo) && (React.createElement(NaverPayCollapse, { result: (_x = (_w = props.data) === null || _w === void 0 ? void 0 : _w.data) === null || _x === void 0 ? void 0 : _x.scheduleNaverPayInfo })),
                React.createElement(Marginer, { height: 10 }),
                isProduct ? (React.createElement(React.Fragment, null,
                    React.createElement(PriceAdjustInput, { methods: props.methods, usingHistory: usingHistory }),
                    React.createElement(ProductInputList, __assign({}, props, { onClickEmpty: handleClickEmpty })))) : (React.createElement(TicketInput, __assign({}, props, { onClickEmpty: handleClickEmpty }))),
                React.createElement(Marginer, { height: 10 }),
                React.createElement(HookFormInputs.Textarea, { label: "\uBA54\uBAA8", inputProps: {}, controlProps: {
                        name: "memo",
                        control: props.methods.control
                    } }),
                React.createElement(Marginer, { height: 30 })))),
            React.createElement(PCSalesFormRight, null,
                React.createElement(CustomerSelector, __assign({}, props)),
                React.createElement("div", { className: "payment" },
                    React.createElement(PaymentInputs, __assign({}, props, { parentType: "sales", hideMethods: getHideMethods(props.methods) }))),
                React.createElement(SalesModalFooter, { left: { buttonProps: { type: 'primary', children: '닫기', onClick: props.onCancel } }, right: {
                        element: (React.createElement(Col, { style: { width: '50%' } },
                            React.createElement(RegisterButtonWithOptions, __assign({ registerType: "sales" }, {
                                children: ((_z = (_y = props.data) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.isUpdated) ? '매출수정' : '매출등록',
                                onClick: props.onSubmit,
                                disabled: disabled
                            }))))
                    } })))));
};
var getHideMethods = function (methods) {
    var ticketType = (methods === null || methods === void 0 ? void 0 : methods.getValues('ticket.copiedTicketTemplate.type')) || (methods === null || methods === void 0 ? void 0 : methods.getValues('ticket.type'));
    return ticketType === TICKET_TYPE.CHARGING_MONEY
        ? [EXTENDED_SALES_METHODS.SHARED_PREPAID_POINT, EXTENDED_SALES_METHODS.PREPAID_POINT]
        : [];
};
export var TimeBaseSelector = function (props) {
    var _a;
    var data = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data;
    var _b = useLocalStorage(LOCAL_STORAGE_KEY_PAID_AT_STANDARD, props.modeKey), mode = _b[0], setMode = _b[1];
    var isScheduleMode = mode === props.modeKey;
    var handleClick = function () {
        var newMode = isScheduleMode ? 'now' : props.modeKey;
        setMode(newMode);
        props.methods.setValue('paidAt', newMode === props.modeKey ? props.modeTime : new Date());
    };
    return !props.isShown ? (React.createElement("div", { style: { display: 'none' } })) : (React.createElement(Rectangle, { onClick: handleClick, style: {
            width: '36px',
            height: '36px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        }, color: isScheduleMode ? COLORS.schedule2 : COLORS.schedule3, children: React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: "clock" }),
            React.createElement(TextComponent, { children: isScheduleMode ? props.modeLabel : '현재시간', type: "caption2" })) }));
};
var CustomerSelector = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var isProduct = ((_a = props.data) === null || _a === void 0 ? void 0 : _a.type) === 'product';
    var isCustomerRequired = ((_b = props.data) === null || _b === void 0 ? void 0 : _b.type) === 'ticket';
    var disabled = (!!((_d = (_c = props.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.isUpdated) && !!((_f = (_e = props.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.customerId)) ||
        // 예약의 매출일 경우 고객 변경 불가능
        (((_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.customerId) && ((_k = (_j = props.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.scheduleId));
    var isDeletedCustomer = !((_l = props.customer) === null || _l === void 0 ? void 0 : _l.id) && ((_o = (_m = props.data) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.id);
    return ((_p = props.data) === null || _p === void 0 ? void 0 : _p.type) ? (React.createElement("div", { className: "customerSearcher" },
        React.createElement(HookFormInputs.CustomerSelector, { inputProps: {
                disabled: disabled,
                isDeletedCustomer: isDeletedCustomer,
                hideControlButtons: true,
                ref: props.customerSelectorRef,
                // FIXME: PC에서는 변경시 유효성 검증을 다시 진행하는데 모바일에서는 2번째 시도시 유효성 검증안해서 임시코드
                onChange: function () {
                    props.methods.trigger('customerId');
                }
            }, controlProps: {
                name: 'customerId',
                control: (_q = props.methods) === null || _q === void 0 ? void 0 : _q.control,
                rules: ((_s = (_r = props.methods) === null || _r === void 0 ? void 0 : _r.formState) === null || _s === void 0 ? void 0 : _s.isDirty) && isCustomerRequired
                    ? {
                        required: {
                            value: true,
                            message: '선불액∙정기권 매출은 고객을 입력해야합니다.'
                        }
                    }
                    : undefined
            } }),
        isProduct && !props.methods.getValues('customerId') ? (React.createElement(Row, { wrap: false, align: "middle" },
            React.createElement(Icon, { name: "alert-triangle", color: COLORS.info }),
            React.createElement(TextComponent, { type: "caption1", marginTop: 5, children: "\uACE0\uAC1D\uC744 \uC785\uB825\uD558\uC9C0 \uC54A\uC73C\uBA74 \uBBF8\uB4F1\uB85D \uACE0\uAC1D\uC73C\uB85C \uC9C4\uD589\uB429\uB2C8\uB2E4.", color: COLORS.info, marginLeft: 5 }))) : (''))) : (React.createElement("div", null));
};
var handleSelectProductOrTicket = function (manager, methods, isProduct, result) {
    var _a;
    // ScheduleModel createSalesModalDataFromThis;
    var scheduleManagerId = methods.getValues('scheduleManagerId');
    var me = manager;
    if (isProduct) {
        var prevUsings = methods.getValues('usingHistory') || [];
        methods.setValue('usingHistory', prevUsings.concat(result.map(function (item) {
            var _a, _b;
            return {
                id: item.id || createUUID(),
                productId: item.productId,
                price: (_a = item.product) === null || _a === void 0 ? void 0 : _a.price,
                amount: 1,
                ticketHistory: item.ticketHistory || {},
                salesPerManagers: [
                    { managerId: scheduleManagerId || (me === null || me === void 0 ? void 0 : me.id), shareRate: 100, sharePrice: (_b = item.product) === null || _b === void 0 ? void 0 : _b.price }
                ]
            };
        })), { shouldDirty: true });
    }
    else {
        var paidAt = methods.getValues().paidAt;
        var template = result;
        methods.setValue('ticket', {
            ticketTemplateId: Number(template.id),
            copiedTicketTemplate: __assign(__assign({}, template), { totalPrice: template.totalPrice, benefitString: template.benefitString }),
            data: template.getDefaultTicketData(paidAt),
            salesPerManagers: [
                { managerId: scheduleManagerId || (me === null || me === void 0 ? void 0 : me.id), shareRate: 100, sharePrice: template.price || 0 }
            ],
            expiredAt: (_a = template.getExpiredAt(paidAt)) === null || _a === void 0 ? void 0 : _a.toDate()
        }, { shouldDirty: true });
        handleClearPrepaidInputs(methods);
    }
    methods.trigger(['usingHistory', 'ticket', 'leftPayment']);
};
export var SalesModalFooter = function (props) {
    return (React.createElement("div", { className: "footer" },
        props.left.element ? props.left.element : React.createElement(Button, __assign({ type: "primary", ghost: true }, props.left.buttonProps)),
        props.right.element ? props.right.element : React.createElement(Button, __assign({ type: "primary" }, props.right.buttonProps))));
};
export var PCSalesFormRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n"])));
export var PCSalesFormLeft = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  & > div {\n    width: 100%;\n    max-width: 650px;\n  }\n  flex: 1;\n  padding-top: 36px;\n  overflow-y: overlay;\n  & > .footer {\n    display: flex;\n    padding-top: 20px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  & > div {\n    width: 100%;\n    max-width: 650px;\n  }\n  flex: 1;\n  padding-top: 36px;\n  overflow-y: overlay;\n  & > .footer {\n    display: flex;\n    padding-top: 20px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"])));
export var PCSalesFormRight = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  min-width: 360px;\n  display: flex;\n  flex-direction: column;\n  width: 360px;\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: overlay;\n  }\n"], ["\n  min-width: 360px;\n  display: flex;\n  flex-direction: column;\n  width: 360px;\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: overlay;\n  }\n"])), COLORS.gray5, COLORS.gray5, COLORS.gray5);
export var MobileSalesFormResult = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: scroll;\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: scroll;\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"])), COLORS.gray5, COLORS.gray5, COLORS.gray5);
export var handleClearPrepaidInputs = function (formMethods, clearPoint) {
    var methods = formMethods.watch('methods');
    if (!methods) {
        return;
    }
    if (methods.prepaidPoint || methods.prepaidPoint) {
        formMethods.setValue('methods', __assign(__assign({}, methods), { prepaidPoint: { deduction: null, active: false }, sharedPrepaidPoint: { deduction: null, active: false }, customerPoint: clearPoint ? { deduction: null, active: false } : methods.customerPoint }), {
            shouldDirty: true
        });
        formMethods.setValue('prepaidPoints', []);
        formMethods.setValue('sharedPrepaidPoints', []);
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
