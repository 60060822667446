var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Button, Card, Layout, Typhography, Inputs } from 'common-ui';
import React from 'react';
import { AdditionalCover, DrawerComponent, DrawerFooter, HookFormInputs, ScrollBar } from 'renderer/components';
import { createNanoId, get, set } from 'renderer/utils';
import { useSensors, useSensor, MouseSensor, TouchSensor, DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from 'common-ui';
import { IMAGES } from 'renderer/assets';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { COLORS } from 'renderer/styles';
var SortableWrapper = function (props) {
    var sensors = useSensors(useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10
        }
    }), useSensor(TouchSensor, {
        activationConstraint: {
            delay: 100,
            tolerance: 5
        }
    }));
    return (React.createElement(DndContext, { sensors: sensors, onDragEnd: props.onDragEnd },
        React.createElement(SortableContext, { items: props.items.map(function (item) { return item.key; }) }, props.child)));
};
export var CustomFieldEditor = function (props) {
    var _a, _b;
    var hasPlan = (_a = props.store) === null || _a === void 0 ? void 0 : _a.hasSubplan('customField');
    var customFields = get((_b = props.store) === null || _b === void 0 ? void 0 : _b.customFields, props.dataKey);
    var _c = React.useState(false), open = _c[0], setVisible = _c[1];
    var defaultValues = set({}, props.dataKey, customFields);
    var updateQuery = StoreQuery.useUpdateStore();
    var form = useForm({
        defaultValues: defaultValues,
        mode: 'onChange'
    });
    var _d = useFieldArray({
        control: form === null || form === void 0 ? void 0 : form.control,
        name: props.dataKey // unique name for your Field Array
    }), fields = _d.fields, remove = _d.remove, insert = _d.insert;
    var watchFieldArray = form.watch(props.dataKey);
    var controlledFields = fields.map(function (field, index) {
        return __assign(__assign({}, field), watchFieldArray[index]);
    });
    React.useEffect(function () {
        if (open) {
            form.reset(defaultValues);
        }
    }, [open]);
    var hadleClose = function () {
        form.reset(defaultValues);
        setVisible(false);
    };
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var store, preference, customFields, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    store = props.store;
                    preference = store.preference;
                    customFields = set(preference.customFields || {}, props.dataKey, values[props.dataKey]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateQuery.mutateAsync({
                            id: store === null || store === void 0 ? void 0 : store.id,
                            preference: __assign(__assign({}, preference), { customFields: customFields })
                        })];
                case 2:
                    _a.sent();
                    notification.success({ message: '나만의 고객필드 설정이 완료되었습니다.' });
                    hadleClose();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    notification.success({ message: '나만의 고객필드 설정 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerComponent, { onClose: function () { return setVisible(false); }, open: open, title: "\uB098\uB9CC\uC758 \uACE0\uAC1D\uD544\uB4DC \uC124\uC815", footer: hasPlan && React.createElement(DrawerFooter, { onConfirm: onSubmit, onCancel: hadleClose, confirmText: "\uC800\uC7A5" }), children: !hasPlan ? (React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%' } },
                React.createElement(AdditionalCover, { store: props.store, type: "customFields", isNarrow: true }))) : (React.createElement(ScrollBar, { style: { height: '100%' } },
                React.createElement("form", null,
                    React.createElement(SortableWrapper, { items: controlledFields, onDragEnd: function (ev) {
                            // onDragEnd={({ active, over }) => {
                            var activeIndex = fields.findIndex(function (item) { return item.key === ev.active.id; });
                            var activeItem = watchFieldArray[activeIndex];
                            var overIndex = fields.findIndex(function (item) { return item.key === ev.over.id; });
                            remove(activeIndex);
                            insert(overIndex, activeItem);
                        }, child: controlledFields.map(function (item, index) {
                            return (React.createElement(CustomFieldCard, { key: item.key, item: item, form: form, onDelete: function (id) {
                                    var activeIndex = fields.findIndex(function (item) { return item.key === id; });
                                    remove(activeIndex);
                                }, name: "".concat(props.dataKey, ".").concat(index) }));
                        }) })),
                React.createElement(Button.ContainButton, { children: "+ \uD544\uB4DC \uCD94\uAC00", fluid: true, size: "big", onClick: function () {
                        insert(fields.length, {
                            label: '필드명',
                            type: 'text',
                            isDefault: false,
                            editable: true,
                            key: createNanoId()
                        });
                    } }))) }),
        React.createElement(Button.PremiumButton, { children: "\uB098\uB9CC\uC758 \uACE0\uAC1D\uD544\uB4DC \uC124\uC815", style: { marginBottom: '20px' }, onClick: function () { return setVisible(true); } })));
};
var CustomFieldCard = function (props) {
    var _a;
    var _b = useSortable({ id: props.item.key }), attributes = _b.attributes, listeners = _b.listeners, setNodeRef = _b.setNodeRef, transform = _b.transform, transition = _b.transition;
    var style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        marginBottom: '10px'
    };
    var isDropdown = props.item.type === 'select';
    return (React.createElement("div", __assign({ ref: setNodeRef, style: style }, attributes),
        React.createElement(Card.CollapsibleCard, { disableOpen: props.item.isDefault, header: React.createElement(Layout.Inline, { align: "center", gutter: 8, stretch: 2, style: { paddingLeft: '10px' } },
                React.createElement("img", __assign({ src: IMAGES.icon_drag_hover, style: { cursor: 'grab' } }, listeners)),
                React.createElement(Typhography.TextComponent, { children: "".concat(props.item.label) })), content: props.item.editable ? (React.createElement(FieldDiv, { key: props.item.id, style: { width: '100%' } },
                React.createElement(Inputs.TextField, { label: "\uD544\uB4DC\uBA85", name: "".concat(props.name, ".label"), options: {
                        required: '필드명을 입력해주세요.',
                        maxLength: {
                            value: 6,
                            message: '필드명은 6자를 넘을 수 없습니다.'
                        }
                    }, placeholder: "\uD544\uB4DC\uBA85\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.", methods: props.form }),
                React.createElement(StyledSelector, { label: "\uD544\uB4DC\uC885\uB958", inputProps: {
                        placeholder: '필드를 선택해주세요.',
                        onChange: function (type) {
                            props.form.setValue("".concat(props.name), __assign(__assign({}, props.item), { type: type, options: type === 'select' ? [] : null }));
                        },
                        options: [
                            {
                                label: React.createElement("img", { src: IMAGES.input_text }),
                                value: 'text'
                            },
                            {
                                label: React.createElement("img", { src: IMAGES.input_textarea }),
                                value: 'textarea'
                            },
                            {
                                label: React.createElement("img", { src: IMAGES.input_datepicker }),
                                value: 'date'
                            },
                            {
                                label: React.createElement("img", { src: IMAGES.input_select }),
                                value: 'select'
                            }
                        ]
                    }, controlProps: { name: "".concat(props.name, ".type"), control: props.form.control } }),
                isDropdown &&
                    ((_a = props.item.options) === null || _a === void 0 ? void 0 : _a.map(function (item, index) {
                        var optionsFieldName = "".concat(props.name, ".options");
                        var options = props.form.getValues(optionsFieldName) || [];
                        return (React.createElement(Layout.FluidInline, { align: "center", style: { marginTop: '10px' }, stretch: 0 },
                            React.createElement(Inputs.TextField, { label: "\uC120\uD0DD\uC9C0 ".concat(index + 1), name: "".concat(props.name, ".options.").concat(index), placeholder: "\uC120\uD0DD\uC9C0\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", methods: props.form, options: {
                                    maxLength: {
                                        value: 6,
                                        message: '선택지는 6자를 넘을 수 없습니다.'
                                    }
                                } }),
                            React.createElement(Icon.ClickableIcon, { name: "x", size: 24, onClick: function () {
                                    return props.form.setValue(optionsFieldName, options.filter(function (_, itemIndex) { return itemIndex !== index; }));
                                } })));
                    })),
                isDropdown && (React.createElement(Button.ContainButton, { style: { marginTop: '10px' }, children: "+ \uC120\uD0DD\uC9C0 \uCD94\uAC00", fluid: true, onClick: function (ev) {
                        ev.stopPropagation();
                        ev.preventDefault();
                        var optionsFieldName = "".concat(props.name, ".options");
                        var options = props.form.getValues(optionsFieldName) || [];
                        props.form.setValue(optionsFieldName, options.concat("\uC120\uD0DD\uC9C0 ".concat(options.length + 1)));
                    } })),
                React.createElement(Layout.FluidInline, { justify: "end", align: "center", style: { marginTop: '24px' } },
                    React.createElement(DeleteDiv, { onClick: function () { return props.onDelete(props.item.key); }, children: React.createElement(Layout.Inline, { align: "center" },
                            React.createElement(Typhography.TextComponent, { children: "\uC0AD\uC81C", color: COLORS.calendarRedActive }),
                            React.createElement(Icon.Icon, { name: "trash-2", color: COLORS.calendarRedActive })) })))) : (React.createElement("div", null)) })));
};
var FieldDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  & .ant-select {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  & .ant-select {\n    width: 100%;\n  }\n"])));
var StyledSelector = styled(HookFormInputs.SelectInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & .ant-select-selector {\n    height: auto !important;\n    padding-top: 5px !important;\n    padding-bottom: 5px !important;\n  }\n"], ["\n  & .ant-select-selector {\n    height: auto !important;\n    padding-top: 5px !important;\n    padding-bottom: 5px !important;\n  }\n"])));
var DeleteDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  & svg {\n    margin-left: 5px;\n  }\n"], ["\n  cursor: pointer;\n  & svg {\n    margin-left: 5px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
