var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, DatePicker, Input, Layout, notification, Row } from 'antd/lib';
import Col from 'antd/lib/col';
import dayjs from 'dayjs';
import React from 'react';
import { CalendarSetter, CALENDER_GRID_TYPE, ClickableText, Icon, SearchControllerCol, typographyByName } from 'renderer/components';
import { ScheduleQuery } from 'renderer/queries';
import { COLORS } from 'renderer/styles';
import { getRanges, times, useDebounce } from 'renderer/utils';
import styled from 'styled-components';
export var ReservationCalendarHeader = function (props) {
    var format = 'YYYY년 MM월 DD일 (dd)';
    var changeUnit = props.view === CALENDER_GRID_TYPE.DAY
        ? 'day'
        : props.view === CALENDER_GRID_TYPE.WEEK
            ? 'week'
            : props.view === CALENDER_GRID_TYPE.MONTH
                ? 'month'
                : null;
    var _a = React.useState(''), keyword = _a[0], setKeyword = _a[1];
    var debouncedKeyword = useDebounce(keyword, 500);
    React.useEffect(function () {
        props.onSearchParamChange(__assign(__assign({}, props.searchParams), { keyword: debouncedKeyword }));
    }, [debouncedKeyword]);
    return (React.createElement(CalendarHeader, null,
        React.createElement(HeaderTop, { justify: "space-between", align: "middle", wrap: false },
            React.createElement(Col, null, changeUnit && (React.createElement(Row, { align: "middle", wrap: false },
                !props.hideControl && (React.createElement(ArrowCol, { onClick: function () { return props.onDateChange(dayjs(props.date).startOf('day').subtract(1, changeUnit).toDate()); } },
                    React.createElement(Icon, { name: "left-chevron", size: 24 }))),
                React.createElement(Col, null,
                    React.createElement(TopDatePicker, { suffixIcon: null, allowClear: false, disabledDate: props.availableDates
                            ? function (current) { var _a; return !((_a = props.availableDates) === null || _a === void 0 ? void 0 : _a.find(function (item) { return dayjs(item).isSame(current, 'day'); })); }
                            : null, format: format, inputReadOnly: true, value: dayjs(props.date), onChange: function (value) { return props.onDateChange(value.toDate()); } })),
                !props.hideControl && (React.createElement(ArrowCol, { onClick: function () { return props.onDateChange(dayjs(props.date).startOf('day').add(1, changeUnit).toDate()); } },
                    React.createElement(Icon, { name: "right-chevron", size: 24 }))),
                React.createElement(Button, { children: "\uC624\uB298", onClick: function () { return props.onDateChange(dayjs().toDate()); }, size: "small" })))),
            !props.hideControl && (React.createElement(Col, { flex: 1 },
                React.createElement(Row, { align: "middle", wrap: false, justify: "end" },
                    React.createElement(SearchControllerCol, null,
                        React.createElement(Input, { prefix: React.createElement(Icon, { name: "search" }), placeholder: '고객명 또는 연락처 검색', size: "small", onChange: function (ev) {
                                setKeyword(ev.target.value);
                            } })),
                    React.createElement(Col, null,
                        React.createElement(CalendarSetter, null)))))),
        props.view === CALENDER_GRID_TYPE.TABLE ? (React.createElement(DateRangeForReservationTable, { onSearchParamChange: props.onSearchParamChange, searchParams: props.searchParams })) : (React.createElement(DateController, { date: props.date, onDateChange: props.onDateChange, availableDates: props.availableDates }))));
};
var TopDatePicker = styled(DatePicker)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0px;\n  padding: 0px;\n  cursor: pointer;\n  box-shadow: 0 0 0 0 rgb(0 0 0 / 0%) !important;\n  & input {\n    cursor: pointer;\n    ", ";\n    width: 130px !important;\n  }\n"], ["\n  border: 0px;\n  padding: 0px;\n  cursor: pointer;\n  box-shadow: 0 0 0 0 rgb(0 0 0 / 0%) !important;\n  & input {\n    cursor: pointer;\n    ", ";\n    width: 130px !important;\n  }\n"])), typographyByName('subtitle2'));
var CalendarHeader = styled(Layout.Header)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 !important;\n  height: 70px !important;\n  background-color: ", " !important;\n"], ["\n  padding: 0 !important;\n  height: 70px !important;\n  background-color: ", " !important;\n"])), COLORS.white);
var HeaderTop = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  padding-left: 14px;\n  height: 40px;\n"], ["\n  border-bottom: 1px solid ", ";\n  padding-left: 14px;\n  height: 40px;\n"])), COLORS.gray6);
var ArrowCol = styled(Col)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  alignitems: flex;\n  cursor: pointer;\n"], ["\n  display: flex;\n  alignitems: flex;\n  cursor: pointer;\n"])));
var DateController = function (props) {
    var date = props.date;
    var start = dayjs(date).startOf('month');
    var end = dayjs(date).endOf('month').date();
    return (React.createElement(HeaderBottom, { justify: "start", align: "middle", wrap: false }, times(end).map(function (value) {
        var _a;
        var hidden = !props.availableDates
            ? false
            : !((_a = props.availableDates) === null || _a === void 0 ? void 0 : _a.find(function (item) { return dayjs(item).isSame(start.add(value, 'day'), 'day'); }));
        var target = start.add(value, 'day');
        var holiday = ScheduleQuery.getHolidayInfo(target.toDate());
        var isSaturday = target.day() === 6;
        return hidden ? (React.createElement("div", null)) : (React.createElement(DateButton, { key: "".concat(value), onClick: function () {
                props.onDateChange(target.toDate());
            }, children: "".concat(value + 1), marginRight: 4, "$holiday": holiday, "$saturday": isSaturday, "$selected": dayjs(date).isSame(target, 'day'), "$today": dayjs().isSame(target, 'day') }));
    })));
};
var DateButton = styled(ClickableText)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 2px;\n  width: 20px;\n  min-width: 20px;\n  height: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 2px;\n  width: 20px;\n  min-width: 20px;\n  height: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"])), function (props) { return (props.$today ? COLORS.primary1 : props.$selected ? COLORS.primary4 : COLORS.white); }, function (props) {
    return props.$today
        ? COLORS.white
        : props.$holiday
            ? COLORS.calendarRedActive
            : props.$saturday
                ? COLORS.info
                : COLORS.gray1;
}, typographyByName('body2'));
export var DateRangeForReservationTable = function (props) {
    var _a, _b;
    return (React.createElement(HeaderBottom, { justify: "start", align: "middle" },
        React.createElement(Col, { span: props.span || 8 },
            React.createElement(StyledRange, { presets: getRanges(dayjs()), allowEmpty: [false, false], value: [dayjs((_a = props.searchParams) === null || _a === void 0 ? void 0 : _a.startedAt), dayjs((_b = props.searchParams) === null || _b === void 0 ? void 0 : _b.endedAt)], onChange: function (values) {
                    if (values.length === 2) {
                        var startedAt = values[0], endedAt = values[1];
                        if (endedAt.diff(startedAt, 'day') > 90) {
                            notification.warning({ message: '90일 이하의 범위로 검색해주세요.' });
                            return;
                        }
                        props.onSearchParamChange({
                            startedAt: startedAt.startOf('day').toDate(),
                            endedAt: endedAt.endOf('day').toDate()
                        });
                    }
                } }))));
};
var StyledRange = styled(DatePicker.RangePicker)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 0px;\n  padding: 0px;\n  &.ant-picker-focused {\n    border-color: #fff;\n    box-shadow: 0 0 0 2px rgb(150 117 248 / 0%);\n    border-right-width: 0px !important;\n    outline: 0;\n  }\n\n  & .ant-picker-active-bar {\n    display: none;\n  }\n"], ["\n  border: 0px;\n  padding: 0px;\n  &.ant-picker-focused {\n    border-color: #fff;\n    box-shadow: 0 0 0 2px rgb(150 117 248 / 0%);\n    border-right-width: 0px !important;\n    outline: 0;\n  }\n\n  & .ant-picker-active-bar {\n    display: none;\n  }\n"])));
export var CALENDAR_HEADER_HEIGHT = 130;
var HeaderBottom = styled(Row)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-left: 14px;\n  height: 30px;\n  overflow-x: auto;\n  overflow-y: hidden;\n"], ["\n  padding-left: 14px;\n  height: 30px;\n  overflow-x: auto;\n  overflow-y: hidden;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
